    @font-face {
        font-family: 'Montserrat Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Montserrat Regular'), url('fonts/Montserrat-Regular.woff') format('woff'), url('fonts/Montserrat-Regular.ttf') format('truetype');
    }
    
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Montserrat Regular', 'Segoe UI', sans-serif;
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Montserrat'; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat Regular', monospace;
        /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
    }